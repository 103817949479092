.App {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 1280px;
  min-height: 780px;
}
@font-face{
  font-family: 'Roboto';
  src: url('/font/Roboto-Light.ttf');
}
div, input, span, select, option, button {
  font-family: Roboto;
}
body {
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {}
