.titleSettingsBlock {
    display: block;
    text-align: left;
}
.settingsScreen {

}
.settingsBlock {
    width: 320px;
    height: 495px;
    background: rgba(17, 18, 28, 1);
    margin: 30px 15px;
    padding: 20px;
    border-radius: 3px;
}
.settingsBlocks {
    color: white;
    display: flex;
    justify-content: center;
    height: 535px;
}
.chooseCamera {

}
.valuesCritical {
    height: 350px;
}
.caseEmergency {
    position: relative;
    height: 470px;
}
.inputBlock {
    width: 280px;
    height: 54px;
    outline: none;
    margin: 30px 0;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #000000;
    padding: 0 20px;
    border-radius: 3px;
}





.checkBoxLabel {
    width: 34px;
    height: 14px;
    display: block;
    position: relative;
    background: rgba(163, 171, 182, 0.15);
    border-radius: 20px;
    margin: 20px 0 0 0;
}
input[type="checkbox"] + span.toggle {
    position: absolute;
    left: 0; top: -3px;
    background: rgba(255, 255, 255, 1);
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
input[type="checkbox"]:checked + span.toggle  	{
    background: rgba(95, 120, 211, 1);
    left: 16px;
}
span.checkboxText {
    position: relative;
    left: 60px;
    top: -5px;
}



.valueBlock {
    width: 280px;
    height: 125px;
}
.labelText {
    position: relative;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #5F78D3;
    top: 23px;
}
.maxTimeLimit {
    display: flex;
    position: relative;
    justify-content: space-between;
}
.labelSecText {
    position: absolute;
    right: 10px;
    top: 23px;
    color: #B1B9C0;
}
.secondInput {
    background: none;
    height: 54px;
    width: 229px;
    color: white;
    font-size: 16px;
    padding: 0 10px;
    margin: 7px 0;
    border: 2px solid #303241;
    box-sizing: border-box;
    border-radius: 3px;
}
.dateInput {
    background: none;
    height: 54px;
    width: 111px;
    color: white;
    font-size: 16px;
    padding: 0 10px;
    margin: 7px 0;
    border: 2px solid #303241;
    box-sizing: border-box;
    border-radius: 3px;
}
.dateInputBlock {
    display: flex;
    width: 229px;
    justify-content: space-between;
}
.buttonInfo {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-image: url("/image/vector.svg");
    border: none;
    margin: 0 10px;
}




input[type=radio] {
    height: 36px;
    width: 36px;
    border: 1px solid white;
    border-radius: 50%;
    -webkit-appearance: none;
    outline: none;
}
input[type=radio]:checked {
    border: 1px solid rgba(95, 120, 211, 1);
}
input[type=radio]:checked + span {
    width: 26px;
    height: 26px;
    background: rgba(95, 120, 211, 1);
    display: inline-block;
    position: relative;
    left: -34px;
    top: -5px;
    border-radius: 50%;
}
.radioInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}
.textReact {
    text-align: left;
    width: 207px;
}
.phoneEmergency {
    width: 280px;
    height: 54px;
    border: 2px solid #303241;
    box-sizing: border-box;
    border-radius: 3px;
    background: none;
    margin: 10px 0;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #B1B9C0;
    padding: 0 20px;
}

.violationMessage {
    margin-top: 25px;
    margin-left: 0;
}
.violationMessageCheckbox {
    display: block;
    width: 29px;
    height: 29px;
    border: 1px solid #5F78D3;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
}
input[type="checkbox"]:checked + span.violationMessageCheckboxToggle  	{
    background-image: url("/image/check.svg");
    display: block;
    width: 13px;
    height: 13px;
    position: absolute;
    margin: 7px 7px;
}
.violationMessageText {
    position: relative;
    left: 60px;
    top: -5px;
    width: 212px;
    display: block;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    text-align: left;
}


.formButtonBlock {
    display: block;
    width: 1020px;
    margin: auto;
}
.settingsButton {
    display: block;
    width: 100%;
    height: 54px;
    border-radius: 3px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin: 10px 0;
    border: 1px solid #5F78D3;
    cursor: pointer;
}
.saveSettings {
    background: #5F78D3;
    box-shadow: 0 4px 12px rgba(95, 120, 211, 0.4);
}
.cancelSettings {
    background: none;
}

input[type=radio],
input[type=checkbox] {
    cursor: pointer;
}
