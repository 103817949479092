.loginScreen {

}
.backgroundImgCompos {
    position: fixed;
    z-index: -1;
    display: flex;
    height: 100%;
    top: 0;
}
.bgImg1 {
    top: 0;
    left: 0;
    height: 100%;
    width: 50vw;
    background-image: url("/image/backgroundImage.svg");
}
.bgImg2 {
    top: 0;
    right: 0;
    height: 100%;
    width: 50vw;
    transform: scale(-1, 1);
    background-image: url("/image/backgroundImage.svg");

}

.registrationScreen {

}
.headerLogin {
    height: 81px;
    display: flex;
    color: white;
    background: #11121c;
    padding: 0;
    align-items: center;
    justify-content: center;
}
.logoBlock {
    display: flex;
    margin: 0 50px;
}
.logoImg {
    margin: 0 15px;
}
.logoTextBlock {
    display: grid;
    text-align: left;
}
.logoTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
}
em {
    font-style: normal;
    color: #5F78D3;
    padding-left: 10px;
}
.logoSlog {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.aboutService {
    font-weight: 500;
    font-size: 16px;
    line-height: 7px;
    letter-spacing: -0.01em;
    margin: 0 25px 0 0;
    text-decoration: underline;
    cursor: pointer;
}
.loginBtn {
    width: 146px;
    height: 46px;
    border: 1px solid #5F78D3;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(95, 120, 211, 0.4);
    border-radius: 3px;
    background: none;
    color: white;
    margin: 0 25px;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.registerBtn {
    width: 212px;
    height: 46px;
    background: #5F78D3;
    box-shadow: 0 4px 12px rgba(95, 120, 211, 0.4);
    border-radius: 3px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.formBox {
    display: block;
    width: 360px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    color: #FFFFFF;
}
.titleLogin {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
}
.formInputLabel {
    width: 100%;
    display: block;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    padding: 5px 20px;
}
.formInput {
    width: 360px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: inset 0 1px 5px #D6D7DB;
    border-radius: 3px;
    padding: 0 25px;
    outline: none;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.01em;
}
.submitBtn {
    width: 360px;
    height: 54px;
    background: #5F78D3;
    box-shadow: 0 4px 12px rgba(95, 120, 211, 0.4);
    border-radius: 3px;
    outline: none;
    margin: 20px 0 40px 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    border: none;
}
.forgetText {
    display: block;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: #5F78D3;
    width: 100%;
    text-align: right;
    padding: 5px;
}
.formLabel {
    margin: 15px 0 0 0;
    display: block;
}
