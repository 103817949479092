.mainScreen {
    height: 100%;
    width: 100%;
    background: #1d1f2d;
}
.routers {
    height: calc(100% - 120px);
    width: 100%;
}
.blurScreen {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    z-index: 0 !important;
}
.wrapScreen {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.modalBlock {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
