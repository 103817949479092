.toolBar {
    background: #11121c;
    width: 100%;
    height: 105px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
}
.toolBarButton {
    width: 170px;
    margin: 0 10px;
    cursor: pointer;
    padding: 10px;
    display: block;
}
.toolBarButton:hover svg,
.toolBarButton:hover span {
    color: aqua;
}
.toolBarButton.active svg,
.toolBarButton.active span {
    color: dodgerblue;
}

.toolBarButton svg {
    height: 40%;
    width: 100%;
    color: white;
}
.toolBarButton span {
    font-size: 16px;
    color: white;
}
