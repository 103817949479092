.cameraView {
    height: 100%;
    width: 100%;
    border-radius: 3px;
    position: relative;
}
.addCameraButtonWrap {
    display: flex;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    background: #11121C;
    border-radius: 6px;
    padding: 20px;
    margin: 5px;
}
.addCameraButton {
    border: 1px solid #5F78D3;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.addBtnBlock {
    cursor: pointer;
}
.plusButton {
    font-size: 9em;
    color: #127fee;
}
.textButton {
    color: white;
    font-size: 1.5em;
}
.imageCamera {
    position: absolute;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    top: 5px;
    border-radius: 5px;
    left: 5px;
}
.titleCamera span {
    background: #1D1F2D;
}

.titleCamera.bigSize {
    height: 36px;
    width: 131px;
}
.titleCamera.middleSize {
    height: 36px;
    width: 131px;
}
.titleCamera.smallSize {
    height: 32px;
    width: 104px;
}

.titleCamera {
    color: white;
    font-size: 1em;
    background: #1D1F2D;
    margin: auto;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0.5em;
}
.wrapTitleCamera {
    width: 100%;
    position: relative;
    z-index: 1;
}
.peopleStateBar {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
}
.togglePeopleState {
    color: white;
    background: #11121C;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    font-family: monospace;
    margin: 5px;
}
.togglePeopleState.bigSize {
    font-size: 14px;
    height: 46px;
    width: 64px;
}
.togglePeopleState.middleSize {
    font-size: 13px;
    height: 32px;
    width: 54px;
}
.togglePeopleState.smallSize {
    font-size: 12px;
    height: 27px;
    width: 44px;
}

.peopleStateImage {
    position: absolute;
    left: 6px;
    background: rgba(29, 31, 45, 0.4);
    border-radius: 60px;
}
.peopleStateImage.bigSize {
    width: 227px;
    height: 46px;
}
.peopleStateImage.middleSize {
    width: 118px;
    height: 32px;
}
.peopleStateImage.smallSize {
    width: 89px;
    height: 27px;
}

.peopleStateIcon {
    position: absolute;
    width: 24px;
    height: 24px;
}
.peopleStateIcon.bigSize {
    left: 73px;
    top: 10px;
}
.peopleStateIcon.middleSize {
    left: 68px;
    top: 3px;
}
.peopleStateIcon.smallSize {
    left: 51px;
    top: 1px;
}

.peopleStateText {
    position: absolute;
    left: 117px;
    top: 14px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
}

.peopleStateCount {
    position: absolute;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.01em;
}
.peopleStateCount.bigSize {
    left: 228px;
    top: 14px;
}
.peopleStateCount.middleSize {
    left: 111px;
    top: 14px;
}
.peopleStateCount.smallSize {
    left: 100px;
    top: 10px;
}
.btnOptionBlock.bigSize {
    padding: 10px;
    width: 49px;
    height: 49px;
}
.btnOptionBlock.middleSize {
    padding: 7px;
    width: 36x;
    height: 36px;
}
.btnOptionBlock.smallSize {
    width: 32px;
    height: 32px;
}
.btnOptionBlock {
    background: #1D1F2D;
    border-radius: 50%;
    margin: 3px;
    color: white;
    cursor: pointer;
}
.btnOptionBlockWrap {
    position: absolute;
    z-index: 1;
    display: flex;
    right: 20px;
    top: 10px;
}
.btnOptionImg.bigSize {

}
.btnOptionImg.middleSize {
    height: 22px;
    width: 22px;
}
.btnOptionImg.smallSize {
    height: 30px;
    width: 20px;
}
.removeCameraBtn.middleSize {
    top: -10px;
    left: 0;
    font-size: 32px;
}
.removeCameraBtn.smallSize {
    left: 0;
    font-size: 28px;
    top: -2px;
}
.removeCameraBtn {
    font-size: 36px;
    transform: rotate(45deg);
    display: block;
    position: relative;
    top: -8px;
    left: 1px;
}
